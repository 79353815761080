<template>
    <el-form
        ref="supplierForm"
        size="small"
        :model="supplierForm"
        :rules="rules"
        label-width="150px"
        class="border border-company-blue rounded self-start relative pt-2 pl-2 pr-2"
        label-position="right"
        v-loading="$waiting.is('supplierloading')"
    >
        <header class="text-l font-semibold mb-3 text-header-blue">Uppdatera Leverantör</header>
        <el-form-item class="font-thin" label="Orgnummer">
            <el-input v-model="supplierForm.orgNo" />
        </el-form-item>
        <el-form-item class="font-thin" label="Leverantörsnamn" prop="name">
            <el-input v-model="supplierForm.originalName" />
        </el-form-item>
        <el-form-item class="font-thin" label="E-post">
            <el-input v-model="supplierForm.email" />
        </el-form-item>
        <el-form-item>
            <el-button @click="closeSection()">Avbryt</el-button>
            <el-button type="primary" @click="onSubmit">Uppdatera</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import Api from "./documents.api";

export default {
    props: {
        preSelectedSupplier: {
            type: Object,
        },
    },

    data() {
        return {
            supplierForm: this.preSelectedSupplier,
            rules: {
                name: [{ required: true, message: "Leverantörsnamn saknas", trigger: "blur", class: "pl-6" }],
            },
        };
    },

    methods: {
        async onSubmit() {
            await new Promise(resolve => this.$refs.supplierForm.validate(valid => valid && resolve()));
            this.$waiting.start("supplierloading");
            let response = await Api.updateSupplier(this.supplierForm);
            this.$notify.success({ title: "Utfört" });
            this.$waiting.end("supplierloading");
            this.$emit("success", response);
        },

        closeSection() {
            this.$emit("close");
        },
    },
};
</script>
